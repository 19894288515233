// src/App.js
import React from "react";
import { Box, Typography, Paper } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Login from "./components/Login";
// import Register from "./components/Register";
// import Home from "./components/Home";
// import StripePayment from './components/StripePayment2';
import CreateLink from "./components/CreateLink";
import { AuthProvider } from "./AuthContext";
import Checkout from './components/Checkout';

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="app-container">
          <Routes>
            {/* <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/*" element={<Home />} /> */}
            <Route path="/:account" element={<Checkout />} />
            <Route path="/create-pay-link" element={<CreateLink />} />
            <Route path="/" element={<CreateLink />} />
            <Route path="/cake-mania" element={<IframeEmbed />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

const IframeEmbed = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor="#f5f5f5"
      p={2}
    >
      <Typography variant="h4" gutterBottom>
        Embedded Website
      </Typography>
      <Paper elevation={3} style={{ width: '100%', maxWidth: 500 }}>
        <iframe
          src={process.env.REACT_APP_APP_LINK}
          width="100%"
          height="600"
          frameBorder="0"
          allowFullScreen
          title="Embedded Website"
          style={{ borderRadius: '8px' }}
        ></iframe>
      </Paper>
    </Box>
  );
};


export default App;
